import React, { FunctionComponent } from "react";
import { DateObject } from "react-multi-date-picker";

import {
    Box,
    Button,
    Modal,
    Paper,
    Typography,
    styled,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Radio,
    MenuItem,
    Select,
    TextField,
    ClickAwayListener,
    Grid
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles"
import { DayCareService, PetData } from "../../blocks/catalogue/src/PetDetailsPageController.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CustomCalendar } from "../../blocks/catalogue/src/PetDetailsPage.web";
export const plusIcon = require('./plus.png')
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CustomDate from "./CustomizeCalenderPo";
import { colors } from "./theme";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


interface Props {
    openModal: boolean;
    closeModal: () => void;
    userPet: PetData[];
    addDogRoom: number;
    addCatRoom: number;
    selectedPriceList: DayCareService;
    selectedDaysList: number[];
    selectedPetId: string[];
    selectedPricing: string;
    openPetModal: boolean;
    additionalServiceDetails: string;
    displayPriceValue: string;
    modalErrorText: string;
    dateErrorText: string;
    handleAdditionalDetails: ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) | undefined;
    handleModal?: (() => void) | undefined;
    closePetModal: (() => void);
    handleType: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
    addRoom: ((selceteddPetId: string, petName: string, petType: string) => void) | undefined;
    checkPrice: (() => void) | undefined;
    addSelectedDays: ((event: React.MouseEvent<HTMLElement, MouseEvent>, dayIndex: number) => void) | undefined;
    getSelectFeild: (() => string);
    handleSubmit?: (() => void) | undefined;
    isDisabled: boolean;
    checkIn: DateObject;
    checkOut: DateObject;
    handleCheckOutDate: ((date: DateObject) => void) | undefined;
    handleCheckInDate: ((date: DateObject) => void) | undefined;
    modalError?: any
};

export interface CustomRadioProp {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const useStyles = makeStyles({
    root: {
        color: 'default',
        '&$checked': {
            color: 'red',
        },
    },
    checked: {},
});

export const CustomRadio: React.FC<CustomRadioProp> = ({ checked, onChange, ...props }) => {
    const classes = useStyles();

    return (
        <Radio
            classes={{
                root: classes.root,
                checked: classes.checked,
            }}
            data-test-id="radioTestId"
            color="default"
            disableRipple
            checkedIcon={
                <span
                    style={styles.radioSpan}
                />
            }
            icon={
                <span
                    style={styles.iconSpan}
                />
            }
            checked={checked}
            onChange={onChange}
            {...props}
        />
    );
};

const DayCareServiceModal: FunctionComponent<Props> = ({
    openModal,
    closeModal,
    userPet,
    addDogRoom,
    addCatRoom,
    selectedPriceList,
    handleAdditionalDetails,
    handleModal,
    closePetModal,
    handleType,
    addRoom,
    checkPrice,
    addSelectedDays,
    getSelectFeild,
    selectedDaysList,
    selectedPetId,
    selectedPricing,
    openPetModal,
    additionalServiceDetails,
    displayPriceValue,
    modalErrorText,
    dateErrorText,
    handleSubmit,
    isDisabled,
    checkIn,
    checkOut,
    handleCheckOutDate,
    handleCheckInDate,
    modalError
}) => {
    const dogmap = userPet.filter(pet => pet.attributes.pet_type === 'dog');
    const catmap = userPet.filter(pet => pet.attributes.pet_type === 'cat');
    const days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

    const renderPetInformation = (
        <ClickAwayListener onClickAway={closePetModal} data-test-id="clickAwayListnerId">
            <div className="menuItemBox">
                {addDogRoom > 0 && dogmap.map((petDatils, dogIndex) => (
                    <CustomMenuItem value={petDatils.attributes.name}
                        data-test-id={`petTestid${dogIndex}`}
                    >
                        <Checkbox
                            onClick={() => { addRoom && addRoom(petDatils.id, petDatils.attributes.name, petDatils.attributes.pet_type) }}
                            checked={selectedPetId.includes(petDatils.id)}
                            className="checkbox"
                            data-test-id={`petCheckboxTestid${dogIndex}`}
                        />
                        <Box className="imgBox">
                            <img src={petDatils?.attributes?.pet_photo} width="100%" height={"100%"} />
                        </Box>
                        <Typography variant="body1" data-test-id={`petNameid${dogIndex}`}>
                            {petDatils.attributes.name}
                        </Typography>
                        <Typography variant="body2">{"(" + petDatils.attributes.pet_type + ")"}</Typography>
                    </CustomMenuItem>
                ))
                }
                {addCatRoom > 0 && catmap.map((petDatils) => (
                    <CustomMenuItem value={petDatils.attributes.name}
                    >
                        <Checkbox
                            onClick={() => { addRoom && addRoom(petDatils.id, petDatils.attributes.name, petDatils.attributes.pet_type) }}
                            checked={selectedPetId.includes(petDatils.id)}
                            className="checkbox"
                        />
                        <Box className="imgBox">
                            <img src={petDatils?.attributes?.pet_photo} width="100%" height={"100%"} />
                        </Box>
                        <Typography variant="body1">
                            {petDatils.attributes.name}
                        </Typography>
                        <Typography variant="body2">{"(" + petDatils.attributes.pet_type + ")"}</Typography>
                    </CustomMenuItem>
                ))
                }
            </div>
        </ClickAwayListener>
    );

    const selectPet = (
        <Box className="fieldBox">
            {isDisabled ?
                <Select
                    displayEmpty
                    renderValue={() => getSelectFeild()}
                    className="selectField"
                    fullWidth
                    disabled
                >
                </Select> :
                <Select
                    displayEmpty
                    renderValue={() => getSelectFeild()}
                    className="selectField"
                    fullWidth
                    disabled
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null
                    }}
                    onClick={handleModal}
                    data-test-id="selectFeildId"
                >
                </Select>
            }
            {openPetModal && renderPetInformation}
        </Box>

    );

    const radioGroup = (
        <RadioGroup
            name="selectedValueSpayed"
            value={selectedPricing}
            onChange={(event) => { handleType && handleType(event) }}
            data-test-id="formDataHandleChange"
        >
            <div className="radioGroup">
                <FormControlLabel
                    value="OneDay"
                    control={
                        <CustomRadio
                            checked={selectedPricing === "OneDay"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleType && handleType(event) }}
                            data-test-id="oneDayTextTestid"

                        />}
                    label={
                        <span
                            style={{
                                fontFamily: "inter",
                                fontSize: "16px",
                                color: "#0F172A",
                                fontWeight: 700
                            }}
                        >
                            One Time
                        </span>
                    }
                />
                <FormControlLabel
                    value="Weekly"
                    control={
                        <CustomRadio
                            checked={selectedPricing === "Weekly"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleType && handleType(event) }}
                        />}
                    label={
                        <span
                            style={{
                                fontFamily: "inter",
                                fontSize: "16px",
                                color: "#0F172A",
                                fontWeight: 700
                            }}
                            data-test-id="weeklyTextTestid"
                        >
                            Repeat Weekly
                        </span>
                    }
                />
            </div>
        </RadioGroup>
    );

    const dayList = days.map((currentDay, dayindex: number) => {
        const getDayFromDate = () => {
            const stringCheckIn = checkIn.toString()
            const stringCheckOut = checkOut.toString()
            const checkInDate = new Date(stringCheckIn)
            const checkOutDate = new Date(stringCheckOut)

            const checkInDay = days[checkInDate.getDay() ? checkInDate.getDay() - 1 : 6]
            const checkOutDay = days[checkOutDate.getDay() ? checkOutDate.getDay() - 1 : 6]

            const diffTime = Math.abs(Number(checkOutDate) - Number(checkInDate));
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

            if(diffDays >= 6){
                 return false
            }
            else if(days.indexOf(checkInDay.toString()) > days.indexOf(checkOutDay.toString())) {
                if (((days.indexOf(checkInDay.toString()) +  diffDays) % 7) >= dayindex)  {
                    return false
                }
                else if (days.indexOf(checkInDay.toString()) <= dayindex){
                    return false 
                }
                else return true 
            }
            else if(dayindex>=days.indexOf(checkInDay.toString()) &&dayindex<= days.indexOf(checkOutDay.toString())) {
                return false
            }             
            else return true
        }

        return (
            <Button
                data-test-id={`dayId${dayindex}`}
                className={selectedDaysList.includes(dayindex) ? "selcetedDayFeild" : (getDayFromDate() || modalError==="These days do not match the criteria.") ? "unclickableDay" : "dayField"}
                disabled={getDayFromDate() || modalError==="These days do not match the criteria."}
                onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => { addSelectedDays && addSelectedDays(event, dayindex) }}
            >
                {currentDay}
            </Button>

        );
    });

    return (
        <SaveModal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={openModal}
        >
            <Paper elevation={0} className="modalPaper">
                <Box className="closeModal">
                    <CloseIcon onClick={closeModal} data-test-id="closeBtn" />
                </Box>
                <Box className="petSetupBox">
                    <Box className="headBox">
                        <Typography variant="body1" data-test-id="dayCareHeading">Doggy Daycare </Typography>
                    </Box>
                    <Box className="contentBox">
                        <div>
                            <Box className="timeBox">
                                <div className="dateBox">
                                    <Typography
                                        variant="body1"
                                        className="sideFirstText"
                                    >
                                        Check in
                                    </Typography>
                                    <CustomCalendar className="calanderBox">
                                        <CustomDate
                                            data-test-id="dogbirth"
                                            value={checkIn}
                                            onChange={handleCheckInDate}
                                            isDisabled={isDisabled}
                                        />
                                        <CalendarTodayIcon
                                            style={styles.iconStyle}
                                        />
                                    </CustomCalendar>


                                </div>

                                <div className="dateBox">
                                    <Typography
                                        variant="body1"
                                        className="sideFirstText"
                                    >
                                        Check out
                                    </Typography>
                                    <CustomCalendar className="calanderBox">
                                        <CustomDate
                                            data-test-id="dogbirthd"
                                            value={checkOut}
                                            onChange={handleCheckOutDate}
                                            isDisabled={isDisabled}
                                        />
                                        <CalendarTodayIcon
                                            style={styles.iconStyle}
                                        />
                                    </CustomCalendar>
                                </div>
                            </Box>
                            {dateErrorText && <p className="errorDateMsg" data-test-id="errordateMessage">{dateErrorText}</p>}
                        </div>
                        <Box className="contentBody">
                            <Typography variant="body1">Select Pets</Typography>
                            {selectPet}
                        </Box>
                        <Box className="contentBody">
                            <Typography variant="body1">
                                How often do you need this service?
                            </Typography>
                            <div className="additionalPoints">
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" spacing={1} alignItems="center" wrap="nowrap">
                                                <FiberManualRecordIcon style={{ fontSize: '1rem', color: colors.primaryBlue }} />&nbsp;
                                                <Typography variant="body1" style={styles.pointText}>If you need a partial week, please create a separate One Time Booking before setting up your Repeat Weekly Bookings.</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" spacing={1} alignItems="center" wrap="nowrap">
                                                <FiberManualRecordIcon style={{ fontSize: '1rem', color: colors.primaryBlue }} />&nbsp;
                                                <Typography variant="body1"  style={styles.pointText}>Repeat Weekly Bookings will always begin with a full week starting on a Monday.</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" spacing={1} alignItems="center" wrap="nowrap">
                                                <FiberManualRecordIcon style={{ fontSize: '1rem', color: colors.primaryBlue }} />&nbsp;
                                                <Typography variant="body1"  style={styles.pointText}>
                                                    You may need to set the check out date one day later to ensure your final day is included. 
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="radioGroupContainer">
                                {radioGroup}
                            </div>
                        </Box>
                        <Box className="contentBody">
                            <Typography variant="body1">Select your days:</Typography>
                            <Box className="daybox">
                                {dayList}
                            </Box>
                            <Box className="priceChangeTextBox">
                                <Typography className="priceChangeText">
                                    **Price changes dynamically based on the number of days selected
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="daybox">
                            <Typography variant="h6" data-test-id="priceTextid">{selectedPricing === "Weekly" ? "Weekly Price " : "Day Care Price "}</Typography>
                            <Typography variant="h5" data-test-id="priceValueId">: £{(Number(displayPriceValue) * selectedPetId.length).toFixed(2)}</Typography>
                        </Box>
                        <Box className="contentBody">
                            <Typography variant="body1">
                                Additional Information*
                            </Typography>
                            <Box className="fieldBox">
                                <TextField
                                    className="textField"
                                    fullWidth
                                    placeholder="Write your additional information here"
                                    data-test-id="additionalChangeId"
                                    value={additionalServiceDetails}
                                    onChange={(event) => { handleAdditionalDetails && handleAdditionalDetails(event) }}
                                    disabled={isDisabled}
                                />
                            </Box>
                            {modalErrorText && <p className="errorMsg" data-test-id="errorMessage">{modalErrorText}</p>}
                        </Box>
                        {!isDisabled && <Box className="btnBox">
                            <Button className="saveBtn" disabled={modalError==="These days do not match the criteria."} onClick={handleSubmit} data-test-id="continueBtn">
                                Continue
                            </Button>
                        </Box>}
                    </Box>
                </Box>
            </Paper>
        </SaveModal>
    );
};

const SaveModal = styled(Modal)({
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    overflowY: "scroll",
    "& .timeBox": {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    "& .btnIcon": {
        marginRight: 6
    },
    "& .addPetBtnBox": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        margin: "30px 0px"
    },
    "& .priceChangeTextBox": {
        width: "100%",
        display: "flex",
        justifyContent: "end",
    },
    "& .priceChangeText": {
        fontFamily: "Inter",
        fontSize: "11px",
        fontStyle: "italic",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "right",
    },
    "& .errorMsg": {
        color: "red",
        fontSize: "16px",
        fontFamily: "Inter"
    },
    "& .errorDateMsg": {
        color: "red",
        fontSize: "16px",
        fontFamily: "Inter",
        margin: 0
    },
    "& .radioGroupContainer": {
        width: "60%"
    },
    "& .additionalPoints": {
        width: "100%"
    },
    "& .radioGroup": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .modalPaper": {
        width: "746px",
        padding: "20px",
        borderRadius: "15px",
        height: "654px",
        overflowY: "scroll",
    },
    "& .closeModal": {
        display: "flex",
        width: "100%",
        flexDirection: "row-reverse",
    },
    "& .petSetupBox": {
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    },
    "& .headBox": {
        "& .MuiTypography-body1": {
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "32px",
            fontFamily: "Inter",
            color: "#0F172A",
        },
    },
    "& .dateBox": {
        position: "relative",
        marginTop: "15px",
        marginRight: "30px",
    },
    "& .calanderBox": {
        marginTop: "0px",
        padding: '1px',
        width: "112%"
    },
    "& .contentBox": {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
    },
    "& .contentBody": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        "& .MuiTypography-body1": {
            color: "#0F172A",
            fontSize: "14px",
            fontFamily: "Inter",
            lineHeight: "22px",
            fontWeight: 700,
        },
        "& .MuiTypography-body2": {
            fontSize: "11px",
            fontWeight: 700,
            lineHeight: "22px",
            fontFamily: "Inter",
            color: "#475569",
            fontStyle: "italic",
        },
        "& .MuiTypography-button": {
            color: "rgba(234, 12, 120, 1)",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "22px",
            textAlign: "left",
            textTransform: "capitalize"
        }
    },
    "& .bulletBox": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        gap: "30%",
    },
    "& .bulletField": {
        display: "flex",
        gap: "15px",
        fontWeight: 400,
        lineHeight: "22px",
        fontFamily: "Inter",
        color: "#0F172A",
    },
    "& .daybox": {
        display: "flex",
        width: "100%",
        "& .MuiTypography-h6": {
            fontSize: "14px",
            color: "#0F172A",
            lineHeight: "22px",
            fontFamily: "Inter",
            fontWeight: 700,
        },
        "& .MuiTypography-h5": {
            lineHeight: "22px",
            color: "#0F172A",
            fontWeight: 400,
            fontFamily: "Inter",
            fontSize: "14px",
        },
    },
    "& .dayField": {
        textAlign: "center",
        padding: "10px",
        border: "1px solid #65C9F1",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "14px",
        width: "97px",
        color: "#1E1E1E",
        cursor: "pointer",
        fontFamily: "Inter"
    },
    "& .unclickableDay": {
        border: "1px solid #65C9F1",
        fontWeight: 400,
        textAlign: "center",
        width: "97px",
        color: "#1E1E1E",
        padding: "10px",
        lineHeight: "14px",
        fontSize: "12px",
        cursor: "not-allowed",
        fontFamily: "Inter"
    },
    "& .selcetedDayFeild": {
        border: "1px solid #65C9F1",
        fontWeight: 400,
        textAlign: "center",
        width: "97px",
        color: "#1E1E1E",
        padding: "10px",
        lineHeight: "14px",
        fontSize: "12px",
        backgroundColor: "rgba(224, 244, 252, 1)",
        cursor: "pointer",
        fontFamily: "Inter"
    },
    "& .btnBox": {
        "& .MuiButton-root": {
            color: "#EA0C78",
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "26px",
            textTransform: "none",
            textDecoration: "underline",
            float: "right",
        },
    },
    "& .fieldBox": {
        position: "relative",
        "& .MuiInput-underline": {
            "&:before": { borderBottom: "0px" },
            "&:after": { borderBottom: "0px" },
            "&:focus-visible": {
                outline: "none",
            },
        },
    },
    "& .menuItemBox": {
        position: "absolute",
        backgroundColor: "white",
        width: "100%",
        zIndex: 1
    },
    "& .textField": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        padding: "8px 8px",
        boxSizing: "border-box",
        "& ::placeholder": {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
            color: "#64748B"
        }
    },
    "& .selectField": {
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: 400,
        color: "#0F172A",
        fontFamily: "Inter",
        border: "1px solid #65C9F1",
        borderRadius: "8px",
        backgroundColor: "#E0F4FC",
        padding: "0px 10px",
        "&::placeholder": {
            fontSize: "18=6px",
            fontWeight: 400,
            lineHeight: "24px",
            fontFamily: "Inter",
            color: "#0F172A",
        },
        "& .MuiSelect-icon": {
            color: "#011342",
            padding: "0px 5px",
        },
    },
});
const CustomMenuItem = styled(MenuItem)({
    display: "flex",
    alignItems: "center",
    gap: "15px",
    backgroundColor: "white",
    width: "100%",
    zIndex: 1,
    borderBottom: "2px solid #B2E4F8",
    "& .checkbox": {
        width: "20px",
        height: "20px",
        borderRadius: "6px",
        color: "#326478"
    },
    "& .MuiTypography-body1": {
        color: "#0F172A",
        fontSize: "18px",
        fontFamily: "Inter",
        lineHeight: "26px",
        fontWeight: 700,
    },
    "& .MuiTypography-body2": {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        fontFamily: "Inter",
        color: "#475569"
    },
    "& .imgBox": {
        width: "65px",
        height: "65px"
    },
    "& img": {
        borderRadius: "50%"
    }

});

const styles = {
    iconStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        right: 0,
        color: "#334155",
        zIndex: 2
    },
    radioSpan: {
        borderRadius: '50%',
        height: 7,
        backgroundColor: 'white',
        border: '6px solid #224350',
        display: 'inline-block',
        width: 7
    },
    iconSpan: {
        borderRadius: '50%',
        border: '1px solid #224350',
        display: 'inline-block',
        height: 16,
        width: 16
    },
    pointText:{
        fontSize: "14px",
        color: "#0F172A",
        lineHeight: "22px",
        fontFamily: "Inter",
        fontWeight: 400,
    }
}
export default DayCareServiceModal;